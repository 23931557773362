//import { API_URL, API_V2_URL, API_QMS_URL, BUCKET_NAME, API_FR_URL } from "@env"

export class Serviceurls {

    // static DOMAIN = "https://dev.api.emedhub.in/v2/";
    // static BASE_DOMAIN = "https://dev.api.emedhub.in/";
    static QMS_DOMAIN = "https://dev.qms.emedhub.in/";


    static UAT_DOMAIN = "https://uat.v3.api.emedhub.in/v2/"
    static UAT_BASE_DOMAIN = "https://uat.v3.api.emedhub.in/"

    // beta or line url ----
    // api.emedhub.in
    // static UAT_DOMAIN = "https://api.emedhub.in/v2/"
    // static UAT_BASE_DOMAIN = "https://api.emedhub.in/"

    static DOMAIN = this.UAT_DOMAIN;
    static BASE_DOMAIN = this.UAT_BASE_DOMAIN;

    // static DOMAIN = API_V2_URL;
    // static BASE_DOMAIN = API_URL;
    // static QMA_DOMAIN = API_QMS_URL;
    // s3 Bucket name in Document Page
    //static s3BUCKET_NAME = BUCKET_NAME

    // user role
    static ROLE_FO = "FO";
    static ROLE_DOCTOR = "doctor";
    static ROLE_OPT = "OPT";
    static ROLE_OPTOMETRY = "OPTOMETRY";
    static ROLE_COUNSELLOR = "COUNSELLOR"

    static LANGUAGES = this.DOMAIN + "languages/"
    static GET_STATE = this.BASE_DOMAIN + "pharmacy/states/";
    static API_SIGN_IN = "auth/login/";
    static HOME_APPOINTMENTS = "doctor/appointments/list/";
    static REG_EX_MOBILENO = /[^.[0-9]]*/

    /**start ---Pharmacy Management API *
    //  * Module List*/
    static GET_MODULES_LIST = "administrator/dashboard/modules";
    static HOME_GET_CLINIC_DOCTORS = "fo/doctors/list/";
    static NEW_APPOINMENT = "v2/doctor/schedule/time/interval/";
    static FO_CREATE_APPOINTMENT = "fo/appointment/";
    static HOME_SEARCH_PATIENT = "api/search/";
    static FO_CREATE_PATIENT = "fo/patient/";
    static HOME_APPOINTMENT_CHANGE_STATUSDR = "fo/appointment/status/";
    static DOCTOR_PATIENT_INFO = "doctor/patient/";
    static FACE_RECOG = "fo/face_recognise/";
    static APPOINTMENT_INFO = "fo/appointment/details/";
    /**Reports - Sales Report */
    static GET_SALES_OVERVIEW = "administrator/pharmacy/reports/sales_overview/";
    static GET_SALES_DETAILED = "administrator/pharmacy/reports/sales_detailed/";
    static GET_SALES_GST = "administrator/pharmacy/reports/sales_gst/";
    static GET_SALES_GST_SPLITUP = "administrator/pharmacy/reports/sales_gst_splitup/";
    static GET_SALES_RETURN = "administrator/pharmacy/reports/sales_return/";
    static GET_SALES_MONTHLY = "administrator/pharmacy/reports/sales_monthly/";
    static GET_SALES_DAYEND = "administrator/pharmacy/reports/sales_dayend/";
    static GET_SALES_CONSOLIDATE = "administrator/pharmacy/reports/sales_consolidated/";
    /**Reports - Purchase Report */
    static GET_PURCHASE_OVERVIEW = "administrator/pharmacy/reports/purchase_overview/"
    static GET_PURCHASE_DETAILS = "administrator/pharmacy/reports/purchase_detailed/"
    static GET_PURCHASE_RETURN = "administrator/pharmacy/reports/purchase_return/"
    static GET_PURCHASE_GST = "administrator/pharmacy/reports/purchase_gst/"
    static GET_PURCHASE_MONTHLY = "administrator/pharmacy/reports/purchase_monthly/"
    /**Reports - Purchase Report */
    /** Stock Adjustment Edit Form */
    static STOCK_ADJUSTMENT_EDIT_FROM = "administrator/pharmacy/inventory/stock_adjustment";
    /** Stock Adjustment Edit Form*/
    /**Inventory - Stock Adjustment*/
    static GET_STOCK_ADJUST_LIST = "administrator/pharmacy/inventory/stock_adjustment"
    static GET_DRUG_LIST = "administrator/dashboard/dosage_types/"
    /**Inventory - Stock Adjustment - History*/
    static GET_STOCK_ADJUST_HISTORY_LIST = "administrator/pharmacy/inventory/stock_adjustment/history/"
    /**Reports - Stock Report ----- start */
    static PHARMACY_REPORT_STOCK_REPORT = "administrator/pharmacy/reports/stock_report/"
    static PHARMACY_REPORT_MIS_REPORT = "administrator/pharmacy/reports/stock_mis/"
    static PHARMACY_REPORT_Lowstock = "administrator/pharmacy/reports/stock_lowstock/"
    static PHARMACY_REPORT_Expiry_Stock = "administrator/pharmacy/reports/stock_expiry/"
    static PHARMACY_REPORT_SlowMovement_Stock = "administrator/pharmacy/reports/stock_slowmovement/"
    static PHARMACY_REPORT_NoMovement_Stock = "administrator/pharmacy/reports/stock_nomovement/"
    /**Reports - Stock Report ----- end */
    /**Pharmacy Management API ---- end*/

}
