import '../App.css';
import '../index.css';
import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Signin from '../Signin/Signin';
import Forgotpassword from '../Signin/ForgotPassword';
import PageNotFound from '../Modules/Upcomingpages/404error';
import { localGetItem } from "../Utility/Services/CacheProviderService";
import Dashboard from '../Modules/Dashboard/Dashboard';
import Settings from '../Modules/Settings/Settings';
import NewPatientRegistration from "../Modules/PatientRegistration/NewPatientRegistration";
import PaymentScreen from '../Modules/PatientRegistration/PaymentScreen';
import ExistingPatientRegistration from "../Modules/PatientRegistration/ExistingPatientRegistration"


function RouterComponent() {
  const isAuthenticated = localGetItem("isLoggedIn")
  return (
    <Switch>
      <Route exact path="/" render={props => !isAuthenticated ? <Signin {...props} /> : <Redirect to="/Dashboard" />} />
      <Route exact path="/ForgotPassword" render={props => <Forgotpassword {...props} />} />
      <Switch>
        {/* <CircularProgress/> */}
        <Route exact path="/Dashboard" render={props => isAuthenticated ? <Dashboard {...props} /> : <Redirect to="/" />} />
        <Route exact path="/NewPatientRegistration" render={props => isAuthenticated ? <NewPatientRegistration {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PaymentScreen" render={props => isAuthenticated ? <PaymentScreen {...props} /> : <Redirect to="/" />} />
        <Route exact path="/ExistingPatientRegistration" render={props => isAuthenticated ? <ExistingPatientRegistration {...props} /> : <Redirect to="/" />} />
        <Route exact from="/Settings" render={props => isAuthenticated ? <Settings {...props} /> : <Redirect to="/" />} />
        <Route exact path="*" render={props => <PageNotFound {...props} />} />
      </Switch>
      {/* </CircularProgress> */}
    </Switch>
  )
}
export default RouterComponent;



