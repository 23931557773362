import * as React from "react";
import Box from "@mui/material/Box";
import RestAPIService from "../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../Utility/API/Serviceurls";
import "./NewPatientRegistration.css";
import "./ExistingPatientRegistration.css"
import Stack from "@mui/material/Stack";
import DoctorIcon from "../../Assets/Logo/doctorlogo.png";
import DaysIcons from "../../Assets/Logo/DaysCount.svg";
import Logo from "../../Assets/Logo/logo.png";
import RightArrow from "../../Assets/Logo/RightArrow.png";
import BackArrow from "../../Assets/Logo/Back-icon.png";
import Contact from "../../Assets/Logo/call-icon.png";
import NumberTag from "../../Assets/Logo/Number.svg";
import Rectangle from "../../Assets/Logo/Rectangle-Icon.svg";
import FaceReg from "../../Assets/Logo/FaceReg-icon.png";
import Curve from "../../Assets/Logo/ButtomCurve.png";
import UnselectedDaysIcon from "../../Assets/Logo/Unselected.png";
import Avator from "../../Assets/Logo/avator.png";
import { MenuItem, FormControl, InputLabel, Select, Button } from "@mui/material";
import Failed from '../../Assets/Animation/Failed.gif';
import Success from '../../Assets/Animation/Success.gif';
import Dialog from "../../Components/Dialog";
import Backspace from "../../Assets/Logo/backspace.png";
import Webcam from "react-webcam";

const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
};

const Listofmonth = [
    { value: 1, Label: "January" },
    { value: 2, Label: "February" },
    { value: 3, Label: "March" },
    { value: 4, Label: "April" },
    { value: 5, Label: "May" },
    { value: 6, Label: "June" },
    { value: 7, Label: "July" },
    { value: 8, Label: "August" },
    { value: 9, Label: "September" },
    { value: 10, Label: "October" },
    { value: 11, Label: "November" },
    { value: 12, Label: "December" },

]

let Days = [];
const today = new Date().getDate();
class ExistingPatientRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.webcamRef = React.createRef();
        const d = new Date();
        let month = d.getMonth();
        const c = new Date();
        let year = c.getFullYear();
        this.state = {
            doctorData: [],
            dialpad: false,
            doctorScheduleTimes: [],
            patientDetails: [],
            currentMonth: month + 1,
            SystemMonth: month + 1,
            currentYear: year,
            Days: [],
            Textval: "",
            IsselectedDay: 1,
            findAmPm: "",
            Doctorid: "",
            isSelectedtime: "",
            IsCurrenttime: false,
            searchNumber: "",
            PatientDropDown: false,
            isSelectedtimeIndex: "",
            isSelectedDayIndex: "",
            isPatientSelected: false,
            patientdata: {},
            dialogBox: false,
            loginSuccess: false,
            TimeerrorMsg: "",
            isCameraClicked: false,
            img: null,
            facePatient: {},
            IsPatientPhotoSelected: false,
            PatientIDFromNumber: "",
        };
    }

    componentDidMount() {
        this.getDoctorList()
        this.getSchuduleTime()
        this.getDates()
        if (this.props.location.state != undefined) {
            this.setState({
                patientdata: this.props.location.state
            }, () => {
                this.getPatientDetails()
            })
        }
    }

    getPatientDetails = () => {
        try {
            var patient_Id = null;
            if (this.state.patientdata.PatientData != undefined) {
                patient_Id = this.state.patientdata.PatientData.data.patient_id;
            } else if (this.state.facePatient.patient_id) {
                patient_Id = this.state.facePatient.patient_id
            } else if (this.state.PatientIDFromNumber != "") {
                patient_Id = this.state.PatientIDFromNumber
            }

            RestAPIService.getAll(Serviceurls.DOCTOR_PATIENT_INFO + patient_Id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            PatientInfo: response.data,
                            IsPatientPhotoSelected: true,
                            isPatientSelected: true
                        })
                    } else {
                        this.setState({ dialogBox: true, ErrorMsg: response.message });
                        setTimeout(() => {
                            this.setState({
                                dialogBox: false,
                            });
                        }, 3000);
                    }
                });
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false,
                });
            }, 3000);
        }
    }

    getDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorData: response.data.data,
                            Doctorid: response.data.data[0].doctor_id
                        }, () => this.getSchuduleTime())
                    } else {
                        this.setState({ dialogBox: true, ErrorMsg: response.message });
                        setTimeout(() => {
                            this.setState({
                                dialogBox: false,
                            });
                        }, 3000);
                    }
                });

        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false,
                });
            }, 3000);
        };

    }

    getSchuduleTime = () => {
        try {
            RestAPIService.getAll(Serviceurls.NEW_APPOINMENT + "?appointment_date=" + this.state.currentYear + "-" + this.state.currentMonth + "-" + this.state.IsselectedDay + "&doctor_id=" + this.state.Doctorid)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorScheduleTimes: response.data.data,
                            TimeerrorMsg: ""
                        })
                    } else {
                        this.setState({
                            TimeerrorMsg: response.data.message,
                            doctorScheduleTimes: ""
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        TimeerrorMsg: "No slots avaiable. Pleace check for another date",
                        doctorScheduleTimes: ""
                    })
                })


        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false,
                });
            }, 3000);
        };
    }

    getDates = () => {
        let today = 1
        if (this.state.SystemMonth == this.state.currentMonth) {
            today = new Date().getDate();
            this.setState({
                IsselectedDay: today
            }, () => { this.getSchuduleTime() })
        }
        else {
            this.setState({
                IsselectedDay: "01",
                isSelectedDayIndex: "",
            }, () => { this.getSchuduleTime() })
        }

        const NumberofDays = new Date(this.state.currentYear, this.state.currentMonth, 0).getDate();
        for (let i = today; i <= NumberofDays; i++) {
            Days.push(i)
        }
        this.setState({
            currentMonth: this.state.currentMonth
        })
    }

    displayVal = (val) => {
        if (this.state.Textval.length < 10) {
            this.setState({
                Textval: this.state.Textval += val
            }, () => {
                this.searchPatient()
            })
        }
    }

    deleteval = () => {
        this.setState({
            Textval: this.state.Textval.slice(0, -1)
        }, () => {
            this.searchPatient()
        })
    }

    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours == '12') {
            hours = '00';
        }

        if (modifier == 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:${"00"}`;
    }

    CreateAppointment = () => {
        const { history } = this.props
        var appointmentTime = this.convertTime12to24(this.state.isSelectedtime);
        try {
            var patient_Id = null;
            if (this.state.patientdata.PatientData != undefined) {
                patient_Id = this.state.patientdata.PatientData.data.patient_id;
            } else if (this.state.facePatient.patient_id) {
                patient_Id = this.state.facePatient.patient_id
            } else if (this.state.PatientIDFromNumber != "") {
                patient_Id = this.state.PatientIDFromNumber
            }
            let data = {
                "patient_id": patient_Id,
                "doctor_id": this.state.Doctorid,
                "purpose_of_visit": "",
                "appointment_patient_type": "",
                "appointment_date": this.state.currentYear + "-" + this.state.currentMonth + "-" + this.state.IsselectedDay,
                "appointment_start_time": this.state.IsCurrenttime ? this.state.isSelectedtime : this.state.isSelectedtimeIndex != 0 ? appointmentTime : "",
                "create_without_schedule": true,
                "patient_tags": ""
            }
            RestAPIService.create(data, Serviceurls.FO_CREATE_APPOINTMENT)
                .then(response => {
                    this.setState({
                        isLoader: false,
                    })
                    if (response.data.status === "success") {
                        this.setState({
                            loginSuccess: true,
                            SuccessMsg: response.data.message
                        }, () => {
                            let month = new Date().getMonth() + 1
                            if (data.appointment_date === new Date().getFullYear() + "-" + month + "-" + new Date().getDate()) {
                                this.ChangeStatus(response.data.data.appointment_id)
                            }
                        })
                        setTimeout(() => {
                            this.setState({
                                loginSuccess: false
                            }, () => {
                                history.push({ pathname: "/PaymentScreen", state: { AppointmentData: response.data.data.appointment_id } })
                            })
                        }, 2000)
                    } else {
                        this.setState({ dialogBox: true, ErrorMsg: response.message });
                        setTimeout(() => {
                            this.setState({
                                dialogBox: false,
                            });
                        }, 3000);
                    }

                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: "Already an appointment is available for this patient on this date" });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false,
                        });
                    }, 3000);
                })
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false,
                });
            }, 3000);
        }
    }

    searchPatient = () => {

        try {
            if (this.state.Textval.length > 9) {
                RestAPIService.getAll(Serviceurls.HOME_SEARCH_PATIENT + "?search_key=" + this.state.Textval)
                    .then((response) => {
                        this.setState({
                            PatientDropDown: true,
                            patientDetails: response.data.data.patient
                        })
                    });
            } else {
                this.setState({
                    PatientDropDown: false,
                })
            }

        }

        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                });
            }, 3000);
        };

    }
    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    capture = () => {
        try {
            const imageSrc = this.webcamRef.current.getScreenshot();
            this.setState({ img: imageSrc })
            const data = new FormData();
            if (!this.state.UpdateProfileClicked) {
                data.append("clinic_id", 1);
                data.append("photo", this.dataURItoBlob(imageSrc))
                RestAPIService.create(data, Serviceurls.FACE_RECOG)
                    .then(response => {
                        if (response.data.status == "success") {
                            if (response.data.data) {
                                this.setState({
                                    facePatient: response.data.data[0],
                                    isLoader: false,
                                    IsPatientPhotoSelected: true,
                                }, () => this.getPatientDetails());
                            } else {
                                this.setState({
                                    dialogBox: true,
                                    ErrorMsg: response.data.message
                                })
                                setTimeout(() => {
                                    this.setState({
                                        dialogBox: false,
                                    });
                                }, 3000);
                            }
                        } else if (response.status == "fail") {
                            this.setState({
                                dialogBox: true,
                                isLoader: false,
                                loginSuccess: false,
                                ErrorMsg: response.message
                            });
                            setTimeout(() => {
                                this.setState({
                                    dialogBox: false,
                                });
                            }, 3000);
                        } else {
                            this.setState({
                                dialogBox: true,
                                isLoader: false,
                                loginSuccess: false,
                                ErrorMsg: response.message
                            });
                            setTimeout(() => {
                                this.setState({
                                    dialogBox: false,
                                });
                            }, 3000);
                        }

                    })
                    .catch((error) => {
                        this.setState({
                            dialogBox: true,
                            isLoader: false,
                            loginSuccess: false,
                            ErrorMsg: error
                        });
                        setTimeout(() => {
                            this.setState({
                                dialogBox: false,
                            });
                        }, 3000);
                    })
            }

            else if (this.state.UpdateProfileClicked) {
                var patient_Id = null;
                if (this.state.patientdata.PatientData != undefined) {
                    patient_Id = this.state.patientdata.PatientData.data.patient_id;
                } else if (this.state.facePatient.patient_id) {
                    patient_Id = this.state.facePatient.patient_id
                } else if (this.state.PatientIDFromNumber != "") {
                    patient_Id = this.state.PatientIDFromNumber
                }
                var states = this.state.PatientInfo.data;
                data.append('patient_id', patient_Id);
                data.append("type", states.title);
                data.append("reffered_by", states.reffered_by);
                data.append("referral_source", states.referral_source);
                data.append("first_name", states.first_name);
                data.append("last_name", states.last_name);
                data.append("gender", states.gender);
                data.append("clinic_id", null);
                data.append("dob", states.dob);
                data.append("age", states.age);
                data.append("marital_status", states.marital_status);
                data.append("mobile_number", states.mobile_number);
                data.append("occupation", states.occupation);
                data.append("receive_sms", states.receive_sms);
                data.append("father_husband_name", states.father_husband_name);
                data.append("country", states.country);
                data.append("mobile_number_2", states.mobile_number_2);
                data.append("pincode", states.pincode);
                data.append("email", "");
                data.append("city_name", states.city_name);
                data.append("address_line_1", states.address_line_1);
                data.append("address_line_2", states.address_line_2);
                data.append("employer_id", states.employer_id);
                data.append("id_no", states.id_no);
                data.append("state_name", states.state_name);
                data.append("photo",this.dataURItoBlob(imageSrc));
                data.append("photo_url", "");
                data.append("face_data", this.dataURItoBlob(imageSrc))
                data.append("approx_dob", states.approx_dob)
                data.append("area_name",states.area_name)
                RestAPIService.update(patient_Id,data, Serviceurls.FO_CREATE_PATIENT)
        .then((response) => {
          if (response.data.status == "success") {
            this.setState({
                UpdateProfileClicked : false
            },()=>{this.getPatientDetails()})
          } else {
            this.setState({ dialogBox: true, ErrorMsg: response.message });
            setTimeout(() => {
              this.setState({
                dialogBox: false,
              });
            }, 3000);
          }
        })
        .catch((error) => {
          this.setState({ dialogBox: true, ErrorMsg: error });
          setTimeout(() => {
            this.setState({
              dialogBox: false,
            });
          }, 3000);
        });
            }
        } catch (e) {
            this.setState({
                dialogBox: true,
                isLoader: false,
                loginSuccess: false,
                ErrorMsg: e.message
            });
            setTimeout(() => {
                this.setState({
                    dialogBox: false,
                });
            }, 3000);
        }
    }

    OpenCameraScreen = () => {
        try {
            return (
                <div className="Container">
                    {this.state.img === null ? (
                        <>
                            <Webcam
                                audio={false}
                                mirrored={true}
                                height={250}
                                width={250}
                                ref={this.webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                            <div className="eMed_DB_CameraBtn">
                                <div style={{ paddingRight: "15px" }}>
                                    <button onClick={this.capture}>Capture Photo</button>
                                </div>
                                <div>
                                    <button style={{ marginTop: '0.5vw' }}
                                        onClick={() => {
                                            this.setState({ img: null, isCameraClicked: false, UpdateProfileClicked:false });
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src={this.state.img} alt="screenshot" />
                            <button onClick={() => this.setState({ img: null })}>Retake</button>
                        </>
                    )}
                </div>
            )
        } catch (e) {

        }
    }

    ChangeStatus = (id) => {
        try {
            let data = {
                "appointment_id": id,
                "appointment_status": "Arrived"
            }
            RestAPIService.create(data, Serviceurls.HOME_APPOINTMENT_CHANGE_STATUSDR)

        }
        catch (e) {

        }
    }

    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false,
            loginSuccess: false
        })
    }

    NopatientAlert = () => {
        this.setState({ dialogBox: true, ErrorMsg: "No Patient Selected" });
        setTimeout(() => {
            this.setState({
                dialogBox: false
            });
        }, 3000);
    }
    NoTimeAlert = () => {
        this.setState({ dialogBox: true, ErrorMsg: "Time Not Selected. Select Your Appointment Time Slot" });
        setTimeout(() => {
            this.setState({
                dialogBox: false
            });
        }, 3000);
    }

    render() {
        const { history } = this.props;
        var now = new Date();
        var currentTime = [
            now.getHours(),
            ':',
            now.getMinutes(),
            ':',
            now.getSeconds()
        ].join('');
        return (
            <div className="eMed_EP_container">
                <div className="eMed_EP_leftContainer">
                    <div>
                        <div>
                            <Box onClick={() => history.goBack()}
                                component="img"
                                sx={{ height: "14vh", width: "7.2vw" }}
                                alt="BackArrow"
                                src={BackArrow}
                            />
                        </div>
                        <div className="eMed_EP_ContentCenterAlign">
                            <Box
                                component="img"
                                sx={{ height: "10vh", width: "22.5vw" }}
                                alt="The house from the offer."
                                src={DoctorIcon}
                            />
                        </div>

                        <div style={{ height: "57.5vh", maxHeight: "57.5vh" }}>
                            {this.state.isPatientSelected == true || this.state.PatientInfo != undefined ?
                                <div>
                                    <div className="eMed_EP_ContentCenterAlign">

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {this.state.PatientInfo != undefined && !this.state.UpdateProfileClicked ?
                                                <Box
                                                    component="img"
                                                    sx={{ marginTop: "2vw", border: "1px solid white", paddingTop: "1vw", paddingBottom: "1vw", paddingX: "1vw", height: "35vh", width: "15vw" }}
                                                    alt="Click to Recognition Face"
                                                    src={this.state.PatientInfo.data.photo_url ? this.state.PatientInfo.data.photo_url : Avator}
                                                />
                                                :
                                                this.state.UpdateProfileClicked ? this.OpenCameraScreen() : null

                                            }
                                            <button onClick={() => { this.setState({ UpdateProfileClicked: true, img: null }) }}>Update Profile Photo</button>
                                        </div>
                                    </div>
                                    <div className="eMed_EP_ContentCenterAlign" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ color: "white", fontWeight: "500", fontSize: "1vw", marginTop: "1vw" }}>
                                            {"Name :  "}{
                                                this.state.PatientInfo != undefined ? this.state.PatientInfo.data.first_name ? this.state.PatientInfo.data.first_name + " " + this.state.PatientInfo.data.last_name: "" : ""}
                                        </div>
                                        {
                                            this.state.PatientInfo != undefined && this.state.PatientInfo.data.patient_account_number && this.state.PatientInfo.data.patient_account_number != "" ?
                                                <div style={{ color: "white", fontWeight: "500", fontSize: "1vw", marginTop: "1vw" }}>
                                                    {"UHID :  "}{
                                                        this.state.PatientInfo != undefined ? this.state.PatientInfo.data.patient_account_number ? this.state.PatientInfo.data.patient_account_number : "" : ""}
                                                </div> : null
                                        }
                                        <div style={{ color: "white", fontWeight: "500", fontSize: "1vw", marginTop: "1vw" }}>
                                            {"Mobile Number :  "}{
                                                this.state.PatientInfo != undefined ? this.state.PatientInfo.data.mobile_number ? this.state.PatientInfo.data.mobile_number : "" : ""}
                                        </div>
                                        <div style={{ color: "white", fontWeight: "500", fontSize: "1vw", marginTop: "1vw" }}>
                                            {"Date Of Birth :  "}{
                                                this.state.PatientInfo != undefined ? this.state.PatientInfo.data.dob != "" ? this.state.PatientInfo.data.dob : this.state.PatientInfo.data.approx_dob : ""}
                                        </div>
                                    </div>
                                </div> :
                                this.state.dialpad == false ?
                                    <div>
                                        <div className="eMed_EP_ContentCenterAlign">
                                            {!this.state.isCameraClicked ?
                                                <Box onClick={() => this.setState({ isCameraClicked: true })}
                                                    component="img"
                                                    sx={{ height: "33vh", width: "12.5vw", marginTop: "5vw" }}
                                                    alt="Click to Recognition Face"
                                                    src={FaceReg}
                                                /> : null}
                                            {this.state.isCameraClicked ?
                                                this.OpenCameraScreen()
                                                : null}
                                        </div>
                                        <div onClick={() => { this.setState({ dialpad: true }) }} className="eMed_EP_ContentCenterAlign">
                                            <div className="eMed_on_click_nav">I  Know  My Mobile Number</div>
                                        </div>
                                    </div>
                                    : <div>
                                        <div className="eMed_EP_ContentCenterAlign">
                                            <div className="eMed_EP_Number_Wrapper">
                                                <img width="45vw" height="48vh" style={{ marginTop: ".5vw" }} src={Contact} />
                                                <Stack spacing={2} sx={{ marginLeft: "1vw" }}>
                                                    <div className="eMed_Dialpad_Message">Receive Your Appointment Details To Your Mobile Number</div>
                                                    <div className="eMed_Number_Display">{this.state.Textval.slice(0, 10)}</div>
                                                    <div className="eMed_Number_Receiver_Container">
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                        <div className="eMed_Number_Receiver"></div>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column", position: "absolute", backgroundColor: "white", borderRadius: 4, width: "100%", top: "100%", zIndex: 10, cursor: "pointer" }}>
                                                        {this.state.patientDetails.length > 0 && this.state.PatientDropDown ? this.state.patientDetails.map((data) => (
                                                            <div onClick={() => { this.setState({ PatientDropDown: false }, () => { this.setState({ PatientIDFromNumber: data.id }, () => { this.setState({ isPatientSelected: true }, () => { this.getPatientDetails() }) }) }) }} style={{ borderBottom: "1px solid gray", height: "3vw", display: "flex", alignItems: "center", paddingLeft: "1vw" }}>{data.first_name} <span style={{ marginLeft: "1vw", color: "gray", fontWeight: 500 }}>{data.patient_account_number + " | " + data.mobile_number}</span> </div>
                                                        )) : null
                                                        }
                                                    </div>
                                                </Stack>
                                                <Box component="img" onClick={() => { this.deleteval() }} src={Backspace} sx={{ width: "3vw", height: "4vh", marginTop: "3vw" }}></Box>
                                                {/* <img width="45vw" height="35vh" style={{marginTop:"2.3vw", marginLeft:"1vw"}} onClick={() => { this.deleteval() }} src={Backspace} /> */}
                                            </div>
                                        </div>
                                        <div className="eMed_EP_ContentCenterAlign">
                                            <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", marginTop: "1.2vw" }}>
                                                <div className="eMed_EP_Dialpad_Container">
                                                    <div onClick={() => { this.displayVal(1) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">1</div>
                                                    </div>
                                                    <div onClick={() => { this.displayVal(2) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">2</div>
                                                    </div>
                                                    <div onClick={() => { this.displayVal(3) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">3</div>
                                                    </div>
                                                </div>
                                                <div className="eMed_EP_Dialpad_Container">
                                                    <div onClick={() => { this.displayVal(4) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">4</div>
                                                    </div>
                                                    <div onClick={() => { this.displayVal(5) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">5</div>
                                                    </div>
                                                    <div onClick={() => { this.displayVal(6) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">6</div>
                                                    </div>
                                                </div>
                                                <div className="eMed_EP_Dialpad_Container">
                                                    <div onClick={() => { this.displayVal(7) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">7</div>
                                                    </div>
                                                    <div onClick={() => { this.displayVal(8) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">8</div>
                                                    </div>
                                                    <div onClick={() => { this.displayVal(9) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">9</div>
                                                    </div>
                                                </div>
                                                <div className="eMed_EP_Dialpad_Container">
                                                    <div onClick={() => { this.displayVal(0) }} className="eMed_EP_Dialpad_Wrapper">
                                                        <Box component="img" src={NumberTag} sx={{ width: "5vw", height: "7vh", margin: "0.5vw" }}>
                                                        </Box>
                                                        <div className="eMed_EP_Dialpad_Text_Container">0</div>
                                                    </div>
                                                </div>
                                            </Stack>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="eMed_EP_ContentRightAlign">
                            <Box
                                component="img"
                                sx={{ height: "3.3vw", width: "3.3vw", marginTop: "1.5vw", marginRight: ".5vw" }}
                                alt="The house from the offer."
                                src={Logo}
                            />
                            <img src={Curve} />
                        </div>
                    </div>
                </div>
                <div className="eMed_EP_rightContainer">
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "1.3vw", marginTop: "2.5vw" }}>
                            <div style={{ marginTop: "1.5vw", fontSize: "1.3vw", fontWeight: 500, color: "teal", marginRight: ".5vw" }}>
                                I Need To Book Appointment With
                            </div>
                            <div>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel sx={{ fontSize: ".85vw", color: "rgb(163, 161, 161)" }} id="demo-simple-select-standard-label">Select Your Doctor</InputLabel>
                                    <Select
                                        sx={{ width: "15vw", color: "black" }}
                                        className="eMed_Select_doctor"
                                        value={this.state.Doctorid}
                                        onChange={(e) => {
                                            this.setState({
                                                Doctorid: e.target.value
                                            }, () => this.getSchuduleTime())
                                        }}
                                    >
                                        {this.state.doctorData.map((doc) => (
                                            doc.doctor_id != "Nursing" ?
                                                <MenuItem value={doc.doctor_id}>{doc.first_name + " " + doc.initial}</MenuItem> : null
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ borderBottom: "2.5px solid teal", width: "23vw" }}></div>
                            <div style={{ color: "teal", marginLeft: "1vw", fontWeight: 'bold' }}>ON</div>
                            <div>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
                                    <Select
                                        value={this.state.currentMonth}
                                        sx={{ backgroundColor: "teal", color: "white" }}
                                        onChange={(e) => {
                                            Days = []
                                            this.setState({
                                                currentMonth: e.target.value
                                            }, () => {
                                                this.getDates()
                                            })
                                        }}
                                    >
                                        {Listofmonth.map((list) => (
                                            this.state.SystemMonth <= list.value ?
                                                <MenuItem value={list.value}>{list.Label}</MenuItem> : null
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ color: "teal", marginLeft: "0.5vw" }}>{this.state.currentYear}</div>
                        </div>
                        <div style={{ marginBottom: "1vw", display: "flex", flexDirection: "row", overflowY: "scroll", maxWidth: "40vw", marginTop: ".8vw" }}>
                            {
                                Days.length > 0 ? Days.map((list, index) => (
                                    <div onClick={() => { this.setState({ IsselectedDay: list > 0 && list < 10 ? "0" + list : list, isSelectedDayIndex: index }, () => { this.getSchuduleTime() }) }} className="eMed_EP_Dialpad_Wrapper">
                                        <Box component="img" src={this.state.isSelectedDayIndex == index ? DaysIcons : UnselectedDaysIcon} sx={{ width: "20vw", height: "15vh" }}></Box>
                                        {/* <img src={this.state.isSelectedDayIndex == index ? DaysIcons : UnselectedDaysIcon} /> */}
                                        <div style={{ color: this.state.isSelectedDayIndex == index ? "white" : "teal" }} className="eMed_EP_Days_Text_Container">{list > 0 && list < 10 ? "0" + list : list}</div>
                                    </div>
                                )) : null
                            }
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ display: "flex", backgroundColor: "teal", height: "2.5vw", width: "8vw", color: "white", alignItems: "center", justifyContent: "center", borderRadius: 4 }}>Slot Timings</div>
                            <div style={{ color: "teal", marginLeft: "1vw" }}>AT</div>
                            <div style={{ borderBottom: "2.5px solid teal", width: "27vw", marginLeft: ".5vw" }}></div>
                        </div>
                        <div style={{ marginTop: "1vw", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <Button onClick={() => { this.setState({ IsCurrenttime: true, isSelectedtime: currentTime, isSelectedtimeIndex: "" }) }} style={{ color: this.state.IsCurrenttime ? "white" : "black", backgroundColor: this.state.IsCurrenttime ? "teal" : "white" }} id="eMed_Select_Current_Time">Now</Button>
                            <div style={{ color: "teal", fontSize: ".95vw", fontWeight: 500, marginTop: ".8vw" }}>OR</div>
                            <div className="eMed_Scheduled_Time_Container">
                                {this.state.doctorScheduleTimes.length > 0 ? this.state.doctorScheduleTimes.map((data, index) => {
                                    var item = { label: data, value: data }
                                    var Index = index + 1;
                                    return (
                                        <Button onClick={() => { this.setState({ isSelectedtime: item.value, isSelectedtimeIndex: Index, IsCurrenttime: false }) }} style={{ color: this.state.isSelectedtimeIndex == Index ? "white" : "teal", backgroundColor: this.state.isSelectedtimeIndex == Index ? "teal" : "white" }} className="eMed_Scheduled_Time">{item.label}</Button>
                                    )
                                }) : <div style={{ color: "red", textAlign: "center", width: "100%" }}>{this.state.TimeerrorMsg}</div>}
                            </div>
                        </div>
                        <div className="eMed_EP_ContentCenterAlign">
                            <div className="eMed_Swipe_Container">
                                <Button onClick={() => { (this.state.isPatientSelected || this.state.IsPatientPhotoSelected) ? (this.state.isSelectedtime != "" || this.state.IsCurrenttime) ? this.CreateAppointment() : this.NoTimeAlert() : this.NopatientAlert() }} id="eMed_Swipe_Wrapper">CONFIRM</Button>
                                <div className="eMed_Swipe_text2">Next Step</div>
                                <Box
                                    component="img"
                                    sx={{ width: "1.3vw", height: "2.2vh" }}
                                    alt="RightArrow"
                                    src={RightArrow}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loginSuccess ? <Dialog
                    type={"loginsucess"}
                    DialogClose={this.dialogCloseFunction}
                    header={"Message"}
                    content={this.state.SuccessMsg}
                    icon={<img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />}
                    secondary={"Close"}
                    closeButton={this.homeScreen} /> : null}
                {this.state.dialogBox ?
                    <Dialog
                        type={"Notification"}
                        DialogClose={this.dialogCloseFunction}
                        header={"Message"}
                        content={this.state.ErrorMsg}
                        icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                        secondary={"Close"}
                    /> : null}
            </div>
        )
    }
}

export default ExistingPatientRegistration;