import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import PaidIcon from '@mui/icons-material/Paid';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import DescriptionIcon from '@mui/icons-material/Description';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SickIcon from '@mui/icons-material/Sick';

export class SideMenuList {
    static SuperAdmin = [
        { id: 1, menuName: 'Dashboard', icon: <DashboardIcon />, },
        { id: 2, menuName: 'Profile Management', icon: <ManageAccountsIcon /> },
        {
            id: 3, menuName: 'OP Management', icon: <SupervisedUserCircleIcon />,
            subMenu: [
                { id: 3.1, subMenuName: 'Home', icon: <HomeIcon /> },
                { id: 3.2, subMenuName: 'Patient Profile', icon: <GroupAddIcon /> },
                { id: 3.3, subMenuName: 'Billing Transaction', icon: <DescriptionIcon /> },
                { id: 3.4, subMenuName: 'OP Configuration', icon: <DeviceThermostatIcon /> }
            ],
        },
        {
            id: 4, menuName: 'IP Management', icon: <AirlineSeatIndividualSuiteIcon />,
            subMenu: [
                { id: 4.1, subMenuName: 'Profile', icon: <EngineeringIcon /> },
                { id: 4.2, subMenuName: 'Patient', icon: <SickIcon /> },

            ],
        },
        { id: 5, menuName: 'Pharmacy Management', icon: <DataSaverOnIcon /> },
        { id: 6, menuName: 'Lab Managemant', icon: <DeviceThermostatIcon /> },
        { id: 7, menuName: 'Accounts', icon: <ManageAccountsIcon /> },
        { id: 8, menuName: 'Support', icon: <SupportAgentIcon /> },
        { id: 9, menuName: 'Settings', icon: <SettingsApplicationsIcon /> }
    ];

    static Admin = [
        { id: 1, menuName: 'Dashboard', icon: <DashboardIcon /> },
        { id: 2, menuName: 'Profile Management', icon: <ManageAccountsIcon /> },
        {
            id: 3, menuName: 'OP Management', icon: <SupervisedUserCircleIcon />,
            subMenu: [
                { id: 3.1, subMenuName: 'Home', icon: <HomeIcon /> },
                { id: 3.2, subMenuName: 'Patient Profile', icon: <GroupAddIcon /> },
                { id: 3.3, subMenuName: 'Billing Transaction', icon: <DescriptionIcon /> },
                { id: 3.4, subMenuName: 'OP Configuration', icon: <DeviceThermostatIcon /> }
            ],
        },
        { id: 4, menuName: 'IP Management', icon: <AirlineSeatIndividualSuiteIcon /> },
        { id: 5, menuName: 'Pharmacy Management', icon: <DataSaverOnIcon /> },
        { id: 6, menuName: 'Lab Managemant', icon: <DeviceThermostatIcon /> },
        { id: 8, menuName: 'Support', icon: <SupportAgentIcon /> },
        { id: 9, menuName: 'Settings', icon: <SettingsApplicationsIcon /> }
    ];

    static User = [
         { id: 1, menuName: 'Dashboard', icon: <DashboardIcon/> },
        { id: 2, menuName: 'Profile Management', icon: <ManageAccountsIcon /> },
        {
            id: 3, menuName: 'OP Management', icon: <SupervisedUserCircleIcon />,
            subMenu: [
                { id: 3.1, subMenuName: 'Home', icon: <HomeIcon /> },
                { id: 3.2, subMenuName: 'Patient Profile', icon: <GroupAddIcon /> },
                { id: 3.3, subMenuName: 'Billing Transaction', icon: <DescriptionIcon /> },
                { id: 3.4, subMenuName: 'OP Configuration', icon: <DeviceThermostatIcon /> }
            ],
        },
        { id: 4, menuName: 'IP Management', icon: <AirlineSeatIndividualSuiteIcon /> },
        {
            id: 5, menuName: 'Pharmacy Management', icon: <DataSaverOnIcon />,
            subMenu: [
                { id: 5.1, subMenuName: 'Home', icon: <HomeIcon /> },
                { id: 5.2, subMenuName: 'Inventory', icon: <InventoryIcon /> },
                { id: 5.3, subMenuName: 'Vendor Management', icon: <CoPresentIcon /> },
                { id: 5.4, subMenuName: 'Transactions', icon: <PaidIcon /> },
                { id: 5.5, subMenuName: 'Drug Database', icon: <VaccinesIcon /> },
                { id: 5.6, subMenuName: 'Reports', icon: <ReceiptLongIcon/> },
            ]
        },
        { id: 8, menuName: 'Support', icon: <SupportAgentIcon /> },
        { id: 9, menuName: 'Settings', icon: <SettingsApplicationsIcon /> }
    ];
} 