import {ReactComponent as DashboardIcon} from '../Assets/Icons/ManagePatientProfile.svg';
export class ImagePaths {
    static Logo = require("../Assets/Logo/logo.png");
    static Accounts = require("../Assets/Icons/Accounts.svg");
    static BranchManagement = require("../Assets/Icons/BranchManagement.svg");
    static Dashboard = <DashboardIcon/>;
    static DrugRegistry = require("../Assets/Icons/DrugRegistry.svg");
    static Home = require("../Assets/Icons/Home.svg");
    static IPManagement = require("../Assets/Icons/IPManagement.svg");
    static Inventory = require("../Assets/Icons/Inventory.svg");
    static LabManagement = require("../Assets/Icons/LabManagement.svg");
    static ManagePatientProfile = require("../Assets/Icons/ManagePatientProfile.svg");
    static OPManagement = require("../Assets/Icons/OPManagement.svg");
    static PharamcyManagement = require("../Assets/Icons/PharamcyManagement.svg");
    static ProfileManagement = require("../Assets/Icons/ProfileManagement.svg");
    static Reports = require("../Assets/Icons/Reports.svg");
    static Support = require("../Assets/Icons/Support.svg");
    static Transactions = require("../Assets/Icons/Transactions.svg");
    static VendorManagement = require("../Assets/Icons/VendorManagement.svg");
    static DocotorIcon = require("../Assets/Logo/doctorlogo.png")
}


export default{DashboardIcon}