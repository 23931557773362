import * as React from "react";
import Box from "@mui/material/Box";
import ComingSoon from "../Upcomingpages/ComingSoon";
import { localSetItem, localGetItem, localClearAll, clearAllCachevalue } from "../../Utility/Services/CacheProviderService";
import RestAPIService from "../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../Utility/API/Serviceurls";
import "./Dashboard.css";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import ImagePaths from "../../Utility/ImagePaths";
import DoctorIcon from "../../Assets/Logo/doctorlogo.png";
import TouchIcon from "../../Assets/Logo/touchicon.png";
import Logo from "../../Assets/Logo/logo.png";
import Curve from "../../Assets/Logo/ButtomCurve.png";
import BackArrow from "../../Assets/Logo/Back-icon.png";
import { Colors } from "../../Styles/Colors";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_Name : JSON.parse(localGetItem("loggedInUserInfo")).data.name
    }
  }
  componentDidMount() {
  }

  logOut = () => {
    const { history } = this.props;
    localClearAll();
    clearAllCachevalue();
    history.push("/")
  }
  render() {
    const { history } = this.props
    const { t } = this.props
    return (
      <div className="eMed_DB_container">
        <div className="eMed_EP_leftContainer">
          <div>
            <div>
              <Box
                // component="img"
                sx={{ height: "14vh", width: "7.2vw" }}
                // alt="BackArrow"
                // src={BackArrow}
              />
            </div>
            <div className="eMed_EP_ContentCenterAlign">
              <Box
                component="img"
                sx={{ height: "10vh", width: "22.5vw" }}
                alt="The house from the offer."
                src={DoctorIcon}
              />
            </div>
            <div style={{ height: "57.5vh", maxHeight: "57.5vh" }}>
              <div>
                <div className="eMed_EP_ContentCenterAlign">
                  <Box
                    component="img"
                    sx={{ marginTop: "10vw", width:"25vw", height:"12vh" }}
                    alt="Click to Recognition Face"
                    src={TouchIcon}
                  />
                </div>
              </div>
            </div>
            <div className="eMed_EP_ContentRightAlign">
              <Box
                component="img"
                sx={{ height: "3.3vw", width: "3.3vw", marginTop: "1.5vw", marginRight: ".5vw" }}
                alt="The house from the offer."
                src={Logo}
              />
              <img src={Curve} />
            </div>
          </div>
        </div>
        <div className="eMed_DB_rightContainer">
       
          <div className="logoutDiv">
          <Button size="large" variant="text" onClick={() => { this.logOut() }} startIcon={<PowerSettingsNewIcon />}>
                    Log out
                  </Button>
          </div>
          <Stack spacing={20}>
            <div className="eMed_DB_ContentCenterAlign">
              <h1><span className="eMed_DB_header">Welcome to &nbsp;&nbsp;</span> <span className="eMed_DB_hdr_Clinic">{this.state.clinic_Name}</span></h1>
            </div>
            <div className="eMed_DB_ContentCenterAlign">
              <Stack spacing={4}>
                <div className="eMed_DB_ContentCenterAlign">
                  <div className="eMed_DB_txtcontentCenter">
                    If you are new to the hospital please click New Patient
                    Registration or click Existing Patient Login to Book
                    appointment
                  </div>
                </div>
                <div className="eMed_DB_ContentCenterAlign">
                  <Button id="eMed_DB_ButtonContainer" variant="contained" onClick={() => { history.push("/NewPatientRegistration") }} endIcon={<SendIcon />}>
                    New Patient Registration
                  </Button>
                </div>
                <div className="eMed_DB_ContentCenterAlign">
                  <Button id="eMed_DB_ButtonContainer" variant="contained" onClick={() => { history.push("/ExistingPatientRegistration") }} endIcon={<SendIcon />}>
                    Existing Patient
                  </Button>
                </div>
              </Stack>
            </div>
            <div className="eMed_DB_ContentCenterAlign">

            </div>
          </Stack>
        </div>
      </div>
    );
  }
}

export default Dashboard;
