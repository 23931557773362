import * as React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import { withRouter } from "react-router-dom";
import RouterComponent from "../Utility/RouterComponent";
import "./NavbarApp.css";
import {
  localGetItem,
  localClearAll,
  clearAllCachevalue,
} from "../Utility/Services/CacheProviderService";
import { SideMenuList } from "../Utility/SideMenuList";
import { Trans, withTranslation } from "react-i18next";
import Loader from "../Components/Loader";
import Success from "../Assets/Animation/Success.gif";
import DialogPop from "../Components/Dialog";

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer_Open: false,
      anchorElUser: false,
      loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
      checked: false,
      globalSearch: "",
      menuId: null,
      selectedMenuId: null,
      selectedSubMenuId: null,
      isLoader: false,
    };
  }
  /* Login UserName Api Function start  */
  componentDidMount() {
    if (localGetItem("isLoggedIn")) {
      var ProfileName = localGetItem("loggedInUserInfo");
      var myUser = JSON.parse(ProfileName);
      this.setState({
        loginName: myUser.login_user_name,
        loginType: myUser.login_user_role_name,
      });
    }
  }
  /* Login UserName Api Function end  */

  resetState = () => {
    this.setState({
      drawer_Open: false,
      anchorElUser: false,
      loggedInuserInfo: "",
      checked: false,
      globalSearch: "",
      menuId: null,
      selectedMenuId: null,
      selectedSubMenuId: null,
      isLoader: false,
    });
  };
  Menuitem = (id) => {
    this.setState({
      meunId: id,
    });
  };
  handleChange = () => {
    this.setState({
      checked: true,
    });
  };

  handleDrawerOpen = () => {
    this.setState({
      drawer_Open: true,
    });
  };
  handleDrawerClose = () => {
    this.setState({
      drawer_Open: false,
    });
  };
  handleOpenUserMenu = () => {
    this.setState({
      anchorElUser: true,
    });
  };
  handleCloseUserMenu = (event) => {
    this.setState({
      anchorElUser: false,
    });
  };
  logout = () => {
    this.resetState();
    localClearAll();
    clearAllCachevalue();
  };

  /*Routing and Loader Functions Start*/
  onClick = () => {
    this.setState({
      isLoader: true,
    });
    setTimeout(() => {
      this.setState({
        isLoader: false,
      });
    }, 1000);
    if (this.state.selectedMenuId == 3) {
      var open = this.state.checked;
      this.setState({
        checked: !open,
      });
    } else if (this.state.selectedMenuId == 4) {
      var open = this.state.checked;
      this.setState({
        checked: !open,
      });
    } else if (this.state.selectedMenuId == 5) {
      var open = this.state.checked;
      this.setState({
        checked: !open,
      });
    }
  };
  /*Routing and Loader Functions End*/

  search = (event) => {
    this.setState({
      globalSearch: event.target.value,
    });
  };
  clearSearch = () => {
    this.setState({
      globalSearch: "",
    });
  };
  ModuleDialogClose = () => {
    this.setState({ menuComp: false });
  };
  dialogCloseFunction = () => {
    this.setState({ ModuleSucessPop: false });
  };

  ModuleSuccess = () => {
    this.setState({ ModuleSucessPop: true });
    setTimeout(() => {
      this.setState({ ModuleSucessPop: false });
    }, 2000);
  };
  render() {
    const { t } = this.props;
    const theme = this.props;
    const { history } = this.props;
    const menuList =
      this.state.loggedInuserInfo && this.state.loggedInuserInfo.is_fo_admin
        ? SideMenuList.SuperAdmin
        : this.state.loggedInuserInfo && this.state.loggedInuserInfo.isAdmin
        ? SideMenuList.Admin
        : SideMenuList.User;
    if (localGetItem("isLoggedIn")) {
      return (
        <div>
          <Box className="eMed_muiDrawer">
            <CssBaseline />
            <Box
              component="main"
              sx={{ flexGrow: 1, minWidth: "10vw", pl: 2, pr: 2 }}
            >
              <RouterComponent />
            </Box>
          </Box>
          <Loader loaderOpen={this.state.isLoader} />
          {this.state.ModuleSucessPop ? (
            <DialogPop
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={"Primary Modules Updated Successfully"}
              icon={
                <img
                  className="eMed_dilboxAnimations"
                  alt="Warning"
                  src={Success}
                />
              }
              secondary={"Close"}
              closeButton={this.dialogCloseFunction}
            />
          ) : null}
        </div>
      );
    } else {
      return <RouterComponent />;
    }
  }
}
export default withRouter(withTranslation()(MiniDrawer));
