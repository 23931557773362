import * as React from "react";
import Box from "@mui/material/Box";
import "./PaymentScreen.css";
import DoctorIcon from "../../Assets/Logo/doctorlogo.png";
import Logo from "../../Assets/Logo/logo.png";
import BackArrow from "../../Assets/Logo/Back-icon.png";
import Curve from "../../Assets/Logo/ButtomCurve.png";
import Avator from "../../Assets/Logo/avator.png";
import TempQR from "../../Assets/Logo/tempQR.png";
import RestAPIService from "../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../Utility/API/Serviceurls";
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';

function formatTime(timeString) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + " : " + minute + (hour < 12 ? " AM" : " PM");
}
class PaymentScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PatientName: "",
      DoctorName: "",
      AppointmentDate: "",
      AppointmentTime: "",
      skipPayment: false,
      TokenNumber: null,
      photo_url:'',
      AppointmentData: {}
    };
  }

  componentDidMount() {
    console.log(this.props.location.state)
    if (this.props.location.state != undefined) {
      this.setState({
        AppointmentData: this.props.location.state
      }, () => {
        this.getPatientData()
      })
    }
  }

  getPatientData = () => {
    try {
      RestAPIService.getAll(Serviceurls.APPOINTMENT_INFO + "?appointment_id=" + this.state.AppointmentData.AppointmentData)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              PatientName: response.data.data.patient_name,
              DoctorName: response.data.data.doctor_name,
              AppointmentDate: response.data.data.appointment_date,
              AppointmentTime: response.data.data.appointment_time,
              TokenNumber: response.data.data.token_number,
              photo_url :response.data.data.photo_url
            })
          } else {
            this.setState({
              // TimeerrorMsg: response.data.message,
              // doctorScheduleTimes: ""
            })
          }
        });


    }
    catch (e) {
      this.setState({ dialogBox: true, ErrorMsg: e.message });
      setTimeout(() => {
        this.setState({
          dialogBox: false,
        });
      }, 3000);
    };
  }

  

  render() {
    const d = new Date();
    let month = d.getMonth();
    const { history } = this.props;
    return (
      <div className="eMed_Payment_container">
        <div className="eMed_Payment_leftContainer">
          <div>
            <div>
              <Box onClick={() => history.push('/Dashboard')}
                component="img"
                sx={{ height: "14vh", width: "7.2vw" }}
                alt="BackArrow"
                src={BackArrow}
              />
            </div>
            <div className="eMed_Payment_ContentCenterAlign">
              <Box
                component="img"
                sx={{ height: "10vh", width: "22.5vw" }}
                alt="The house from the offer."
                src={DoctorIcon}
              />
            </div>
            <div style={{ height: "57.5vh", maxHeight: "57.5vh" }}>
              <div>
                <div className="eMed_Payment_ContentCenterAlign">
                  <Box
                    component="img"
                    sx={{ marginTop: "4vw", border: "1px solid white", paddingTop: "1vw", paddingBottom: "1vw", paddingX: "1vw", height: "35vh", width: "15vw" }}
                    alt="Click to Recognition Face"
                    src={this.state.photo_url != "" ? this.state.photo_url :Avator}
                  />
                </div>
                <div className="eMed_Payment_ContentCenterAlign">
                  <div className="eMed_on_patient_name">
                    {`Hello , ${this.state.PatientName}`}
                  </div>
                  <div className="eMed_on_details">
                    {`Your Appoinment With ${this.state.DoctorName} On ${this.state.AppointmentDate} at ${formatTime(this.state.AppointmentTime)} is Confirmed.`}
                  </div>
                </div>
              </div>
            </div>
            <div className="eMed_Payment_ContentRightAlign">
              <Box
                component="img"
                sx={{ height: "3.3vw", width: "3.3vw", marginTop: "1.5vw", marginRight: ".5vw" }}
                alt="The house from the offer."
                src={Logo}
              />
              <img src={Curve} />
            </div>
          </div>
        </div>
        <div className="eMed_Payment_rightContainer">
          {!this.state.skipPayment ?
            <div className="eMed_Payment_ContentCenterAlign">
              <div className="eMed_Payment_QRHead">
                Pay Via QR code
              </div>
              <Box
                component="img"
                sx={{ height: "40vh", width: "20vw" }}
                alt="Click to Recognition Face"
                src={TempQR}
              />
              <div className="eMed_Payment_amountTextdiv">
                <div className="eMed_Payment_amountText">
                  Scan And Pay INR  200 /-

                </div>
                <div className="eMed_Payment_amountText">or</div>
              </div>
              <div onClick={() => { this.setState({ skipPayment: true }, () => {
                if(this.state.skipPayment){
                  setTimeout(() => {
                    this.props.history.push('/Dashboard');
                  }, 5000);
                }
              }) }} className="eMed_Payment_ContentCenterAlign">
                <div className="eMed_on_click_skip_pay">Skip, Online Payment</div>
              </div>
              <div className="eMed_Payment_noteText">
                If You Reached Hospital and To Confirm Your Arrival and Confirm your Timings
                Swipe or Click Arrived
              </div>
            </div> :
            <div className="eMed_Payment_ContentCenterAlign">

              <div className="eMed_Payment_TokenHead">
                { this.state.TokenNumber != null ? "Your Token Number is" : "Your Next Appointment On"}
              </div>
              <div className="eMed_Payment_TokenMain">
                <div className={this.state.TokenNumber != null ? "eMed_Payment_TokenNum_Txt": "eMed_Payment_Date_Txt"}>
                  {this.state.TokenNumber != null ? this.state.TokenNumber : this.state.AppointmentDate}
                </div>
              </div>
              <div className="eMed_Payment_Home_Button"  >
                  <Button variant="text" onClick={() => { history.push("/Dashboard") }} startIcon={<HomeIcon />}>
                    Back To Home
                  </Button>
                </div>
            </div>}
        </div>
      </div >
    )
  }
}

export default PaymentScreen;