import * as React from "react";
import Box from "@mui/material/Box";
import ComingSoon from "../Upcomingpages/ComingSoon";
import {
  localSetItem,
  localGetItem,
} from "../../Utility/Services/CacheProviderService";
import RestAPIService from "../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../Utility/API/Serviceurls";
import "./NewPatientRegistration.css";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import ImagePaths from "../../Utility/ImagePaths";
import BackArrow from "../../Assets/Logo/Back-icon.png";
import Rectangle from "../../Assets/Logo/Rectangle-Icon.svg";
import FaceReg from "../../Assets/Logo/FaceReg-icon.png";
import Curve from "../../Assets/Logo/ButtomCurve.png";
import RightArrow from "../../Assets/Logo/RightArrow.png";
import DoctorIcon from "../../Assets/Logo/doctorlogo.png";
import TouchIcon from "../../Assets/Logo/touchicon.png";
import OpenCamera from "../../Assets/Logo/opencamera.png";
import Logo from "../../Assets/Logo/logo.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DateTime } from "luxon";
import {
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  FormHelperText,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Webcam from "react-webcam";
import Failed from "../../Assets/Animation/Failed.gif";
import Success from "../../Assets/Animation/Success.gif";
import Dialog from "../../Components/Dialog";

const videoConstraints = {
  width: 420,
  height: 420,
  facingMode: "user",
};

class NewPatientRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.state = {
      username: "",
      error: "",
      helperText: "",
      type: "Mr",
      gender: "m",
      first_name: "",
      surName: "",
      dob: "",
      age: "",
      approx_dob: "",
      maritalStatus: "",
      mobileNo: "",
      receiveSMSFlag: true,
      country: "India",
      state: "",
      fatherOrHusband: "",
      address: "",
      pincode: "",
      loginUserDetails: JSON.parse(localGetItem("loggedInUserInfo")),
      PatientData: [],
      isCameraClicked: false,
      img: null,
      dialogBox: false,
      loginSuccess: false,
      ErrorText: "",
      ImageErrorText: "",
    };
  }
  componentDidMount() {
  }
  /* Get Dosage Type API Start */

  numberValidation(num) {
    return /^\d+$/.test(num);
  }
  OnlyTextWithSpace(text) {
    return /^[a-z A-Z.,_]+$/.test(text);
  }
  validate = (event) => {
    const name = event.target.name;
    var value = event.target.value;
    if (name == "age") {
      var isValid = this.numberValidation(event.target.value)
      if ((event.target.value < 150) && (isValid || event.target.value === "")) {
        this.setState({
          [event.target.name]: value,
        });
      }
    }
    else if (name == "pincode") {
      var isValid = this.numberValidation(event.target.value)
      if ((isValid || event.target.value === "")) {
        this.setState({
          [event.target.name]: value,
        });
      }
    }
    else if (name == "mobileNo") {
      var isValid = this.numberValidation(event.target.value)
      if ((isValid || event.target.value === "")) {
        this.setState({
          [event.target.name]: value,
        });
      }
    }
    else if (name == "first_name") {
      var isValid = this.OnlyTextWithSpace(event.target.value)
      if ((isValid || event.target.value === "")) {
        this.setState({
          [event.target.name]: value,
        });
      }
    }
    else {
      this.setState({
        [event.target.name]: value,
      });
    }
  };
  handleAgeCalculation = (date) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.setState({
      age: age,
    });
    return age;
  };
  dobCalculation = (age) => {
    var cntOfYears = age * 365 * 24 * 60 * 60;
    var cntOfMonths = age * 365 * 24 * 60 * 60;
    var cntOfDays = age * 365 * 24 * 60 * 60;

    var birthDate = new Date(new Date().getFullYear() - cntOfYears);
    var birthDate = new Date(new Date().getMonth() - cntOfMonths);
    var birthDate = new Date(new Date().getDay() - cntOfDays);
    this.setState({
      dob: birthDate,
      approx_dob: birthDate,
    });
  };

  //Create Patient
  CreatePatient = () => {
    const { history } = this.props;
    try {
      var states = this.state;
      var data = new FormData();
      data.append("type", states.type);
      data.append("gender", states.gender);
      data.append("first_name", states.first_name);
      data.append("surName", "");
      data.append("dob", states.dob);
      data.append("age", states.age);
      data.append("approx_dob", states.dob);
      data.append("maritalStatus", "");
      data.append("mobile_number", states.mobileNo);
      data.append("receiveSMSFlag", true);
      data.append("country", "India");
      data.append(
        "state_name",
        states.loginUserDetails.data.default_value.default_state
      );
      data.append(
        "city_name",
        states.loginUserDetails.data.default_value.default_city
      );
      data.append("address", states.address);
      data.append("pincode", states.pincode);
      data.append("email", "");
      data.append("address_line_1", "");
      data.append("address_line_2", "");
      data.append("area_name", "");
      data.append("father_husband_name", "");
      data.append(
        "photo",
        this.state.img && this.state.img.length > 500
          ? this.dataURItoBlob(this.state.img)
          : ""
      );
      data.append("photo_url", "");
      data.append(
        "face_data",
        this.state.img && this.state.img.length > 500
          ? this.dataURItoBlob(this.state.img)
          : ""
      );

      RestAPIService.create(data, Serviceurls.FO_CREATE_PATIENT)
        .then((response) => {
          if (response.data.status == "success") {
            this.setState({
              loginSuccess: true,
              SuccessMsg: response.data.message,
            });
            setTimeout(() => {
              this.setState(
                {
                  loginSuccess: false,
                },
                () => {
                  history.push({
                    pathname: "/ExistingPatientRegistration",
                    state: { PatientData: response.data },
                  });
                }
              );
            }, 2000);
          } else {
            this.setState({ dialogBox: true, ErrorMsg: response.message });
            setTimeout(() => {
              this.setState({
                dialogBox: false,
              });
            }, 3000);
          }
        })
        .catch((error) => {
          this.setState({ dialogBox: true, ErrorMsg: error });
          setTimeout(() => {
            this.setState({
              dialogBox: false,
            });
          }, 3000);
        });
    } catch (e) {
      this.setState({ dialogBox: true, ErrorMsg: e.message });
      setTimeout(() => {
        this.setState({
          dialogBox: false,
        });
      }, 3000);
    }
  };
  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  capture = () => {
    const imageSrc = this.webcamRef.current.getScreenshot();
    this.setState({ img: imageSrc });
    const data = new FormData();
    data.append("clinic_id", 1);
    data.append("photo", this.dataURItoBlob(imageSrc));
    RestAPIService.create(data, Serviceurls.FACE_RECOG).then((response) => {
      if (response.data.status == "success") {
        this.setState({
          isLoader: false,
        });
      } else {
        this.setState({ dialogBox: true, ErrorMsg: response.message });
        setTimeout(() => {
          this.setState({
            dialogBox: false,
          });
        }, 3000);
      }
    });
  };

  OpenCameraScreen = () => {
    try {
      return (
        <div className="Container">
          {this.state.img === null ? (
            <>
              <Webcam
                audio={false}
                mirrored={true}
                height={250}
                width={250}
                ref={this.webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <div className="eMed_DB_CameraBtn">
                <div style={{ paddingRight: "15px" }}>
                  <button onClick={this.capture}>Capture Photo</button>
                </div>
                <div>
                  <button style={{ marginTop: '0.5vw' }}
                    onClick={() => {
                      this.setState({ img: null, isCameraClicked: false });
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <img src={this.state.img} alt="screenshot" />
              <button onClick={() => this.setState({ img: null })}>
                Retake
              </button>
            </>
          )}
        </div>
      );
    } catch (e) { }
  };

  dialogCloseFunction = () => {
    this.setState({
      dialogBox: false,
      loginSuccess: false,
    });
  };

  MandatoryValidation = () => {
    if (
      this.state.type != "" &&
      this.state.gender != "" &&
      this.state.first_name != "" &&
      this.state.dob != "" &&
      this.state.age != "" &&
      this.state.address != "" &&
      (this.state.mobileNo != "" && this.state.mobileNo.length == 10) &&
      (this.state.pincode != "" && this.state.pincode.length == 6) &&
      (this.state.img != null)
    ) {
      this.setState({
        ErrorText: "",
        ImageErrorText: "",
      }, () => { this.CreatePatient() })
    } else {

      if (this.state.first_name == "") {
        this.setState({
          ErrorText: "Please Enter Patient Name"
        })
      }
      else if (this.state.mobileNo == "") {
        this.setState({
          ErrorText: "Please Enter Contact Number"
        })
      }
      else if (this.state.mobileNo.length != 10) {
        this.setState({
          ErrorText: "Contact Number Must Be in 10 Digits"
        })
      }
      else if (this.state.gender == "") {
        this.setState({
          ErrorText: "Please Select Gender"
        })
      }
      else if (this.state.dob == "") {
        this.setState({
          ErrorText: "Please Select Date Of Birth"
        })
      }
      else if (this.state.age == "") {
        this.setState({
          ErrorText: "Please Enter Age"
        })
      }
      else if (this.state.address == "") {
        this.setState({
          ErrorText: "Please Enter Address"
        })
      }
      else if (this.state.pincode == "") {
        this.setState({
          ErrorText: "Please Enter Pincode"
        })
      }
      else if (this.state.pincode.length != 6) {
        this.setState({
          ErrorText: "Pincode Must Be In 6 digits"
        })
      }
      else if (this.state.img == null) {
        this.setState({
          ErrorText: "Please Capture Patient Image."
        })
      }

    }
  }

  render() {
    const onKeyDown = (e) => {
      e.preventDefault();
    };
    const { history } = this.props;
    const { t } = this.props;
    return (
      <div className="eMed_DB_container">
        <div className="eMed_EP_leftContainer">
          <div>
            <div>
              <Box
                onClick={() => history.goBack()}
                component="img"
                sx={{ height: "14vh", width: "7.2vw" }}
                alt="BackArrow"
                src={BackArrow}
              />
            </div>
            <div className="eMed_EP_ContentCenterAlign">
              <Box
                component="img"
                sx={{ height: "10vh", width: "22.5vw" }}
                alt="The house from the offer."
                src={DoctorIcon}
              />
            </div>
            <div style={{ height: "57.5vh", maxHeight: "57.5vh" }}>
              <div>
                <div className="eMed_EP_ContentCenterAlign">
                  {!this.state.isCameraClicked ? (
                    <Box
                      onClick={() => this.setState({ isCameraClicked: true })}
                      component="img"
                      sx={{ height: "33vh", width: "12.5vw", marginTop: "5vw" }}
                      alt="Click to Recognition Face"
                      src={FaceReg}
                    />
                  ) : null}
                  {this.state.isCameraClicked ? this.OpenCameraScreen() : null}
                </div>
              </div>
            </div>
            <div className="eMed_EP_ContentRightAlign">
              <Box
                component="img"
                sx={{
                  height: "3.3vw",
                  width: "3.3vw",
                  marginTop: "1.5vw",
                  marginRight: ".5vw",
                }}
                alt="The house from the offer."
                src={Logo}
              />
              <img src={Curve} />
            </div>
          </div>
        </div>
        <div className="eMed_DB_rightContainer">
          <Stack spacing={10}>
            <div className="eMed_DB_ContentCenterAlign">
              <form style={{ width: "50vw" }}>
                <div className="eMed_DB_lbl_valueContainer">
                  <div className="eMed_DB_lbl">
                    <h1> {"First Name"}</h1>
                  </div>
                  <div class="login_input">
                    <div>
                      <Select
                        style={{ width: "7vw" }}
                        size="medium"
                        onChange={(event) => {
                          this.setState({ type: event.target.value }, () => {
                            if (this.state.type == "Mr") {
                              this.setState({
                                gender: 'm'
                              })
                            }
                            else if (this.state.type == "Ms" || this.state.type == "Mrs") {
                              this.setState({
                                gender: 'f'
                              })
                            }
                          });
                        }}
                        value={this.state.type}
                        labelId="demo-simple-select-required-label"
                        label={"Select"}
                        id="emed_demo-simple-select-required"
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Ms"}>Ms</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Baby of"}>Baby of</MenuItem>
                        <MenuItem value={"Master"}>Master</MenuItem>
                        <MenuItem value={"Baby"}>Baby</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div class="login_input">
                    <div>
                      <FormControl>
                        <InputLabel sx={{ fontSize: ".85vw", color: "rgb(163, 161, 161)", marginLeft: "1vw" }} id="demo-simple-select-standard-label">Gender</InputLabel>
                        <Select
                          style={{ width: "7.2vw", marginLeft: ".5vw" }}
                          size="medium"
                          onChange={(event) => {
                            this.setState({ gender: event.target.value });
                          }}
                          value={this.state.gender}
                          labelId="demo-simple-select-required-label"
                          label={"Gender"}
                          id="emed_demo-simple-select-required"
                        >
                          <MenuItem value={"m"}>Male</MenuItem>
                          <MenuItem value={"f"}>Female</MenuItem>
                          <MenuItem value={"t"}>Trans</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div class="login_input">
                    <div>
                      <TextField
                        error={this.state.error}
                        helperText={this.state.helperText}
                        name="first_name"
                        value={this.state.first_name}
                        onChange={this.validate}
                        style={{ width: "18.5vw", marginLeft: ".5vw" }}
                        placeholder={"First Name"}
                      />
                    </div>
                  </div>
                </div>
                <div className="eMed_DB_lbl_valueContainer">
                  <div className="eMed_DB_lbl">
                    <h1> {"Age"}</h1>
                  </div>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* <DatePicker
                        label={"DOB"}
                        inputFormat="dd/mm/yyyy"
                        value={this.state.dob}
                        onChange={(newValue) => {
                          console.log(newValue)
                          // this.setState(
                          //   {
                          //     dob: DateTime.fromJSDate(newValue).toFormat(
                          //       "yyyy-MM-dd"
                          //     ),
                          //   },
                          //   () => this.handleAgeCalculation(this.state.dob)
                          // );
                        }}
                        renderInput={(params) => <TextField error={false} {...params} helperText={null} />}
                      /> */}
                      <DatePicker
                        openTo="year"
                        views={['year', 'month', 'day']}
                        label="DOB"
                        value={this.state.dob}
                        onChange={(newValue) => {
                          if(newValue != "Invalid Date"){
                            if(newValue!= null && newValue.getFullYear() > 1900 ){
                              this.setState({ dob: DateTime.fromJSDate(newValue).toFormat(
                                      "yyyy-MM-dd"
                                    ) }, () => this.handleAgeCalculation(this.state.dob))
                            }
                          }
                          
                        }}
                        renderInput={(params) => <TextField sx={{width:'20vw'}} {...params} helperText={null} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <div class="login_input">
                    <div>
                      <TextField
                        error={this.state.error}
                        helperText={this.state.helperText}
                        name="age"
                        value={this.state.age}
                        onChange={this.validate}
                        style={{ width: "13.2vw", marginLeft: ".5vw" }}
                        placeholder={"Age"}
                      />
                    </div>
                  </div>
                </div>
                <div className="eMed_DB_lbl_valueContainer">
                  <div className="eMed_DB_lbl">
                    <h1> {"Address"}</h1>
                  </div>
                  <div class="login_input">
                    <div>
                      <TextField
                        error={this.state.error}
                        helperText={this.state.helperText}
                        name="address"
                        value={this.state.address}
                        onChange={this.validate}
                        style={{ width: "22.2vw" }}
                        placeholder={"Address"}
                      />
                    </div>
                  </div>
                  <div class="login_input">
                    <div>
                      <TextField
                        error={this.state.error}
                        helperText={this.state.helperText}
                        name="pincode"
                        value={this.state.pincode}
                        onChange={this.validate}
                        style={{ width: "11vw", marginLeft: ".5vw" }}
                        placeholder={"Pincode"}
                        inputProps={{ maxLength: 6 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="eMed_DB_lbl_valueContainer">
                  <div className="eMed_DB_lbl">
                    <h1> {"Contact"}</h1>
                  </div>
                  <div class="login_input">
                    <div>
                      <TextField
                        error={this.state.error}
                        helperText={this.state.helperText}
                        name="mobileNo"
                        value={this.state.mobileNo}
                        onChange={this.validate}
                        style={{ width: "33.6vw" }}
                        placeholder={"10 Digit Mobile Number"}
                        inputProps={{ maxLength: 10 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="eMed_DB_Error_txt">
                  {this.state.ErrorText}
                </div>

                <div class="btn">
                </div>
              </form>
            </div>
            <div className="eMed_DB_ContentCenterAlign">
            </div>
            <div className="eMed_DB_ContentCenterAlign">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <div className="eMed_Swipe_Container">
                  <Button onClick={() => this.MandatoryValidation()} id="eMed_Swipe_Wrapper">
                    Book
                  </Button>
                  <div className="eMed_Swipe_text2">Appointment</div>
                  <Box
                    component="img"
                    sx={{ width: "1.3vw", height: "2.2vh" }}
                    alt="RightArrow"
                    src={RightArrow}
                  />
                </div>
                <div className="eMed_DB_ContentCenterAlign">
                  <h2 style={{ textAlign: "center", marginLeft: "-5vw" }}>
                    If you reached hospital and To confirm your arraival and
                    confirm your Timings Swipe or Click Arrived
                  </h2>
                </div>
              </div>
            </div>
          </Stack>
        </div>
        {this.state.loginSuccess ? (
          <Dialog
            type={"loginsucess"}
            DialogClose={this.dialogCloseFunction}
            header={"Message"}
            content={this.state.SuccessMsg}
            icon={
              <img
                className="eMed_dilboxAnimations"
                alt="Sucess"
                src={Success}
              />
            }
            secondary={"Close"}
            closeButton={this.dialogCloseFunction}
          />
        ) : null}
        {this.state.dialogBox ? (
          <Dialog
            type={"Notification"}
            DialogClose={this.dialogCloseFunction}
            header={"Message"}
            content={this.state.ErrorMsg}
            icon={
              <img
                className="eMed_dilboxAnimations"
                alt="Warning"
                src={Failed}
              />
            }
            secondary={"Close"}
            closeButton={this.dialogCloseFunction}
          />
        ) : null}
      </div>
    );
  }
}

export default NewPatientRegistration;
