import axios from "axios";
import { localGetItem } from "../../Utility/Services/CacheProviderService";

// const UAT_BASE_DOMAIN = "https://uat.v3.api.emedhub.in/"; //UAT API URL
const UAT_BASE_DOMAIN = "https://api.emedhub.in/"; //Production API URL

const API_HEADERS = axios.create({
  baseURL: UAT_BASE_DOMAIN,
  headers: {
    "Content-type": "application/json",
    "Authorization": "Token " + localGetItem("AccessToken")
  }
});

class RestAPIService {
  getAll(URL) {
    return API_HEADERS.get("/" + URL);
  }
  get(id, URL) {
    return API_HEADERS.get(`/${URL}/${id}`);
  }
  getListbyData(id, data, URL) {
    return API_HEADERS.get(`${URL}/${id}`, data);
  }
  create(data, URL) {
    return API_HEADERS.post("/" + URL, data);
  }
  update(id, data, URL) {
    return API_HEADERS.put(`/${URL}${id}/`, data);
  }
  delete(id, URL) {
    return API_HEADERS.delete(`/${URL}/${id}`);
  }
  deleteAll(URL) {
    return API_HEADERS.delete(`/${URL}`);
  }
  findByTitle(title, URL) {
    return API_HEADERS.get(`/${URL}?title=${title}`);
  }
}

export default new RestAPIService();